<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Métier" />
          </div>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Ajouter un métier</div>
              </v-row>
            </v-card-title>

            <!-- le nom -->
            <InputFieldComponent label="nom">
              <template v-slot:value>
                <v-text-field
                  placeholder="Le nom du métier"
                  dense
                  v-model="name"
                  class="field-uppercase"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <InputFieldComponent 
              label="marque métier / domaine"
              :divider=false>
              <template v-slot:value>
                <v-autocomplete
                  placeholder="Sélectionner"
                  :items="domainBrands"
                  item-value="id"
                  no-data-text="aucune donnée"
                  v-model="domainBrandId"
                  clearable
                >
                </v-autocomplete>
              </template>
            </InputFieldComponent>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!canSave"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import InputFieldComponent from "@/components/ui/InputFieldComponent.vue";

import { MetierService } from "@/service/sfr/metier_service.js";
import { DomainBrandService } from "@/service/sfr/domain_brand_service.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "AddMetier",
  components: { Workflow, TitleAndReturnComponent, InputFieldComponent },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**le service d'accès au APIs. */
      serviceMetier: null,

      serviceDominBrand: null,

      name: null,

      /**la liste des marques domaines utilisables */
      domainBrands: [],

      /** la marque / domaine associée au métier */
      domainBrandId: null,
    };
  },
  methods: {
    async load() {
      let items = await this.serviceDominBrand.getAll();

      //on rajoute un adaptateur pour l'affichage dans la comobobox
      items.forEach((item) => {
        item.text = item.brand + " / " + item.domain;
      });

      this.domainBrands = items;

      // console.log("items:" + JSON.stringify(this.domainBrands, null, 4));
    },
    async save() {
      try {
        let metier = {};
        metier.name = this.name.toUpperCase();
        metier.domainBrandId = this.domainBrandId;

        await this.serviceMetier.create(metier);

        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "ajouter : " + (exceptions.toMessage(error) || "problème technique")
        );
      }
    },
  },
  computed: {
    canSave() {
      if (!this.name) return false;
      if (!this.domainBrandId) return false;

      return true;
    },
  },
  mounted() {
    this.serviceMetier = new MetierService(this.$api.getMetierApi());

    this.serviceDominBrand = new DomainBrandService(
      this.$api.getDomainBrandsApi()
    );

    this.load();

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);
  },
};
</script>

<style>
</style>